
import { Options, Vue } from 'vue-class-component';
import Breadcrumb from '@/components/layout/Breadcrumb.vue';
import { Edit, Delete, Attention, CheckOne } from '@icon-park/vue-next';
import NoData from '@/components/layout/NoData.vue';
import Pagination from '@/components/layout/Pagination.vue';

@Options({
    components: {
        Breadcrumb, Edit, Delete, Attention, CheckOne,
        NoData, Pagination
    },
    data(){
        return {
            equipments: {
                data: []
            },
            notification: {
                show: false,
                content: '',
                state: 'error'
            }
        }
    },
    beforeRouteUpdate(to, from ,next){
        console.log(this)
        this.getEquipments(Number(to.params.id));
        next();
    },
    mounted(){
        if(this.$route.params.id)
            this.getEquipments(Number(this.$route.params.id));
    },
    methods: {
        getEquipments(id: number){
            this.$api.xrEquipments(id).then((res: any) => {
                console.log(res)
                this.equipments = res.data;
            })
        },
        handlerDelete(id: number, index: number){
            this.$api.xrDeleteEquipment(id).then((res: any) => {
                console.log(res);
                if(res.msg === 'success'){
                    this.equipments.data.splice(index, 1);
                    this.notification = {
                        show: true,
                        content: '设备删除成功',
                        state: 'success'
                    }
                } else {
                    this.notification = {
                        show: true,
                        content: res.msg,
                        state: 'error'
                    }
                }
            })
        }
    }
})

export default class PageEquipments extends Vue {}
