<template>
    <breadcrumb btnText="新建产品" @btn-click="$router.push('equipments/create')" />
    <div class="page-equipments">

        <div class="equipments-table">
            <table class="table">
                <thead>
                    <td width="60">id</td>
                    <td width="160">栏目</td>
                    <td width="180">名称</td>
                    <td width="220">作者</td>
                    <td width="100">访问人数</td>
                    <td>发布时间</td>
                    <td width="140">操作</td>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in equipments.data" :key="index">
                        <td>{{ item.id }}</td>
                        <td>{{ item.category.title }}</td>
                        <td>{{ item.name }}</td>
                        <td>{{ item.author }}</td>
                        <td>{{ item.visits }}</td>
                        <td>{{ item.published_at }}</td>
                        <td>
                            <div class="operation">
                                <n-button class="btn" 
                                    type="primary" 
                                    @click="$router.push(`/content/category/${item.cat_id}/equipments/edit/${item.id}`)"
                                >
                                    <edit theme="outline" size="16" fill="#fff"/>
                                </n-button>
                                <n-popconfirm 
                                    position="bottom right"
                                    content="确定永久删除这个设备吗？"
                                    @confirm="handlerDelete(item.id, index)"
                                >
                                    <n-button class="btn" type="danger">
                                        <delete theme="outline" size="16" fill="#fff"/>
                                    </n-button>
                                </n-popconfirm>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>

            <no-data v-if="!equipments.data"></no-data>
            <!-- 翻页 -->
            <pagination v-if="equipments.links" v-model:list="equipments"></pagination>
        </div>

    </div>

    <n-notification 
        v-model:show="notification.show"
        :content="notification.content"
    >
        <template v-slot:icon>
            <attention v-if="notification.state === 'error'" theme="outline" size="24" fill="#ff2121"/>
            <check-one v-else theme="outline" size="24" fill="#00c12b"/>
        </template>
    </n-notification>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import Breadcrumb from '@/components/layout/Breadcrumb.vue';
import { Edit, Delete, Attention, CheckOne } from '@icon-park/vue-next';
import NoData from '@/components/layout/NoData.vue';
import Pagination from '@/components/layout/Pagination.vue';

@Options({
    components: {
        Breadcrumb, Edit, Delete, Attention, CheckOne,
        NoData, Pagination
    },
    data(){
        return {
            equipments: {
                data: []
            },
            notification: {
                show: false,
                content: '',
                state: 'error'
            }
        }
    },
    beforeRouteUpdate(to, from ,next){
        console.log(this)
        this.getEquipments(Number(to.params.id));
        next();
    },
    mounted(){
        if(this.$route.params.id)
            this.getEquipments(Number(this.$route.params.id));
    },
    methods: {
        getEquipments(id: number){
            this.$api.xrEquipments(id).then((res: any) => {
                console.log(res)
                this.equipments = res.data;
            })
        },
        handlerDelete(id: number, index: number){
            this.$api.xrDeleteEquipment(id).then((res: any) => {
                console.log(res);
                if(res.msg === 'success'){
                    this.equipments.data.splice(index, 1);
                    this.notification = {
                        show: true,
                        content: '设备删除成功',
                        state: 'success'
                    }
                } else {
                    this.notification = {
                        show: true,
                        content: res.msg,
                        state: 'error'
                    }
                }
            })
        }
    }
})

export default class PageEquipments extends Vue {}
</script>

<style lang="scss" scoped>
.page-equipments{
    @extend .flex-column-center;
    border-radius: $radius;
    background-color: $white;
    box-shadow: $shadow-default;
    .page-head{
        @extend .flex-row-between;
        width: 100%;
        padding: 15px;
        h4{
            @extend .flex-column-middle;
            margin: 0;
        }
    }
    .equipments-table{
        width: 100%;
    }
}
</style>